body {
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 2px;
  background: rgba(217, 217, 217, 0.19);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.d-none {
  display: none !important;
}
