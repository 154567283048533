html {
  scroll-behavior: smooth;
}
@font-face {
    font-family: "Hess Gothic Round NF W01 Bold";
    src: url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.eot");
    src: url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/8e7c4f35c950275e55f5225f17fd13f3.svg#Hess Gothic Round NF W01 Bold")format("svg");
}
@font-face {
    font-family: "Hess Gothic Round NF W01 Rg";
    src: url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.eot");
    src: url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.eot?#iefix")format("embedded-opentype"),
    url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.woff2")format("woff2"),
    url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.woff")format("woff"),
    url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/32c2e4d0dc8c3bae2905940e9c4b5f96.svg#Hess Gothic Round NF W01 Rg")format("svg");
}
.landing-container {
    width: 100vw;
    height: 100vh;
    background-image: url(../imgs/mainBg.png);
    background-size: cover;
    overflow: hidden;
    position: relative;
}
.landing {
    padding-left: 215px;
}
.landing-container .brand {
    margin-top: 53px;
}
.title {
    color: #FFF;
    font-family: 'Gilroy-Light', sans-serif;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 153.846% */
    letter-spacing: 1.56px;
    text-transform: capitalize;
    display: flex;
    margin-top: 163px;
    column-gap: 10px;
}
.title .zbot {
    transform: translateY(-11px);
}
.title .beta {
    transform: translateX(-120px) translateY(-11px);
}
.title .try {
    display: block;
    transform: translateX(-110px);
}
.description {
    margin-top: 35px;
    color: white;
}
.description {
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Rg";
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 1.2px;
    text-transform: capitalize;
    text-wrap: nowrap;
}
.description .think-def {
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 1.68px;
    text-transform: capitalize;
}
.rate-us {
    margin-top: 52px;
    color:#01B9FD;
    font-family: "Hess Gothic Round NF W01 Rg";
    font-size: 18px;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    /* width: 150%; */
}
.dots {
    margin-top: 32px;
    display: flex;
    column-gap: 23.5px;
    cursor: pointer;
}
.canvas-bot-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}
.canvas-bot {
    position: absolute;
    height: 80%;
    right: 0;
    top: 0;
    width: 658px;
    background-color: black;
    border-bottom: 2px solid #00FEFC;
    border-top: 2px solid #0002FE;
    border-radius: 50px 0 0 50px;
    transition-duration: 1s;
}
.canvas-bot .toggle-btn {
    cursor: pointer;
    position: absolute;
    z-index: 1;
    width: 46px;
    background-image: linear-gradient(103deg, #0002FE -41.67%, #00FEFC 141.58%);
    text-align: center;
    border-radius: 10px 0 0 10px;
    height: calc(100vh - 2px);
    transform: translateY(-1px);
}
.canvas-bot .toggle-btn .btn-togg {
    position: absolute;
    left: -15px;
    top: 50%;
    transform: translateY(-50%) rotate(0.5deg);
}
.canvas-bot .toggle-btn .top-sec {
    margin-top: 29px;
    height: fit-content;
}
.canvas-bot .header {
    position: absolute;
    left: -78px;
    top: 130px;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.6px;
    margin: 0;
    width: 200px !important;
    transform: rotate(-90deg);
}
.canvas-bot .bottom-sec {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 60px;
}
.reset-cont {
    background-color: white;
    width: 33px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-top: 12px;
}
.messageing-area {
    margin: auto;
    position: relative;
    width: 521px;
    height: 60px;
    flex-shrink: 0;
    border-radius: 80px;
    border: 1.5px solid #00a1fe;
}
.messageing-area svg {
    position: absolute;
    width: 43px;
    height: 43px;
    background:  linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    right: 20px;
    bottom: 9px;
    cursor: pointer;
}
.messageing-area .svg {
    position: absolute;
    right: 20px;
    bottom: 9px;
    cursor: pointer;
}
.messageing-area .svg .spinner-grow {
  z-index: 2;
}
.messageing-area input {
    width: 80%;
    height: 40px;
    margin-left: 20px;
    margin-top: 7px;
    background-color: transparent;
    font-family: "Hess Gothic Round NF W01 Bold";
    outline: none;
    border: none;
    color: white;
    padding: 0 20px;
    font-size: 16px;
}
.content-container {
  height: 76%;
}
.content {
    height: 65vh;
    padding-left: 50px;
    margin-top: 30px;
    padding-top: 23px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 30px;
    transition-duration: 1s;
}
.con {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100%;
}
@media(max-width : 1300px){
  .content-container {
    height: calc(80vh - 100px);
  }
  .content {
  }
}
.content .bot-message-container:not(:first-child) {
  margin-top: 30px;
}
.hidden-canvas {
    transform: translateX(612px);
    transition-duration: 1s;
}
.op-0 {
    opacity: 0%;
    transition-duration: .3s;
}
.op-100 {
    opacity: 100%;
    transition-duration: .3s;
} 
.d-2 {
    transition-delay: 1s;
}
.click-me {
    border-radius: 30px 30px 0px 30px;
    background: #23C1FF;
    width: fit-content;
    position: absolute;
    right: 80px;
    width: 121px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 10px 2px 10px 18px; */
    color: #000;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 1px;
    top: 50%;
    transform: translateY(-50%);
}
.confirm-form-overlay {
    position: fixed !important;
    height: 100vh;
    width: 100vw;
    background-color: #0000006d;
    top: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.confirm-form {
    width: 510px;
    height: 316px;
    position: relative;
    border-radius: 16px; /* Set the border radius for rounded corners */
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .confirm-form::before {
    width: 516px;
    height: 322px;
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    background: linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%);
    border-radius: 20px; /* Match the border radius of the main element */
    z-index: -1;
  }
  .exit-icon-confirm {
    position: absolute;
    right: 13px;
    top: 16px;
    padding: 5px;
    cursor: pointer;
  }
  .confirm-form h3 {
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.25px;
    text-transform: capitalize;
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-top: 42px;
  }
  .confirm-form div p {
    max-width: 405px;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Rg";
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.1px;
    text-transform: capitalize;
  } 
  .confirm-form .btns-form {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 46px;
    column-gap: 27px;
  }
  .confirm-form .btns-form span {
    display: block;
    color: rgba(255, 255, 255, 0.81);
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    cursor: pointer;
  }
  .confirm-form .btns-form span.confirm {
    display: flex;
    width: 136px;
    height: 54px;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    color: white;
  }
  .thx-form-overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: #0000006d;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 2;
  }
  .thx-form {
    width: 510px;
    height: 364px;
    position: relative;
    border-radius: 16px; /* Set the border radius for rounded corners */
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .thx-form::before {
    width: 516px;
    height: 370px;
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    background: linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%);
    border-radius: 20px; /* Match the border radius of the main element */
    z-index: -1;
  }
  .true-sign {
    margin-top: 37px;
    position: relative;
  }
  .true-sign .true {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .thx-form h3 {
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: capitalize;
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 19px;
    margin-bottom: 11px;
  }
  .thx-form p {
    color: #FFF;
    text-align: center;
    font-family: "Hess Gothic Round NF W01 Rg";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    max-width: 392px;
    margin: 0;
  }
  .thx-form .have-say {
    display: block;
    position: absolute;
    bottom: 41px;
    color: white;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    width: 242px;
    height: 54px;
    border-radius: 80px;
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: .3s;
  }
  .thx-form .have-say:hover {
    transform: scale(1.05);
    transition-duration: .3s;
  }
  .have-say-2 {
    display: block;
    color: #FFF;
    font-family: "Hess Gothic Round NF W01 Bold";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.9px;
    text-transform: capitalize;
    width: 242px;
    height: 54px;
    border-radius: 80px;
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: .3s;
    margin-top: 31px;
  }
  .have-say-2:hover {
    transform: scale(1.05);
    transition-duration: .3s;
  }
  .Robot {
    position: absolute;
    bottom: 20px;
    right: 50px;
    cursor: pointer;
    display: none;
    animation-name: bot-bottom;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  .Robot img {
    position: absolute;
    z-index: 2;
    left: -113px;
    bottom: -3px;
  }
  .Robot-Bg {
    position: absolute;
    background-color: #000;
    width: 65px;
    height: 65px;
    bottom: 13px;
    z-index: 0;
    left: -93px;
    border: 1px solid #00a1fe;
    border-radius: 50%;
    box-shadow: 0px 0px 10px #0002FE;
  }
  .try-Zbot {
    display: none;
    color: white; 
    background: var(--few, linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%));
    width: fit-content;
    padding: 20px 30px;
    position: absolute;
    bottom: 130px;
    right: 47px;
    border-radius: 30px;
    justify-content: center;
    align-items: center;
    font-family: "Hess Gothic Round NF W01 Bold";
    letter-spacing: 1px;
  }
  .try-Zbot::after {
    content: "";
    position: absolute;
    border: 15px solid transparent;
    border-top-color:#007fef ;
    width: 0px;
    height: 0px;
    bottom: -26px;
    left: 57px;
  }
  @media(min-width : 10px) {
    .d-none-canvas-responsive {
      transform: translateX(0px);
      width: 598px !important;
      height: 86%;
      overflow: hidden;
      right: 110px;
      top: 90px;
      border: 3px solid #007fef;
      border-radius: 30px;
    }
    .d-none-2 {
        display: none !important;
    }
    .d-block-canvas-responsive {
      display: block !important;
    }
    .hidden-canvas {
      width: 0px !important;
      height: 0px !important;
    }
    .go-top {
      animation-name: bot-top;
      animation-duration: 1s;
      animation-fill-mode: forwards;
    }
  }
  @media(max-width : 830px) {
    .landing {
        padding-left: 115px;
    }
  }
  @media(max-width : 770px) {
    .Robot {
      right: 10px;
    }
    .d-none-canvas-responsive {
      right: 70px;
    }
    .hidden-canvas {
      opacity: 0;
    }
    .try-Zbot {
      right: 10px;
    }
  }
  @media(max-width : 720px) {
    .landing {
        padding-left: 50px;
    }
  }
  @media(max-width : 700px) {
    .canvas-bot {
      width: 80% !important;
    }
    .content {
      padding-left: 20px;
      height: 65vh;
      margin-right: 10px;
    }
    .chat-text {
      font-size: 13px !important;
      max-width: 80% !important;
    }
    .chat-text-user {
      font-size: 13px !important;
      max-width: 200px;
    }
    .messageing-area {
      width: 320px;
    }
    .messageing-area input {
      width: 60%;
      font-size: 14px;
    }
  }
  @media(max-width : 640px) {
    .title {
        font-size: 20px;
        width: 600px;
        /* flex-wrap: wrap; */
    }
    .title .zbot {
        transform: translateY(-11px) translateX(-15px) scale(0.8);
    }
    .title .beta {
        transform: translateX(-125px) translateY(-11px) scale(0.8);
    }
    .title .try {
        display: block;
        transform: translateX(-135px);
    }
    .description {
        font-size: 16px;
    }
    .description .think-def {
        font-size: 22px;
    }
    .rate-us {
        transform: scale(.8) translateX(-55px);
    } 
    .try-Zbot {
      right: 10px;
      transform: scale(.8);
      bottom: 120px;
    }
  }
  @media(max-width : 385px) {
    .title {
        font-size: 20px;
        width: 600px;
        /* flex-wrap: wrap; */
    }
    .title .ZBOT {
        transform: translateY(-11px) translateX(-15px) scale(0.7);
    }
    .title .beta {
        transform: translateX(-155px) translateY(-11px) scale(0.7);
    }
    .title .try {
        display: block;
        transform: translateX(-175px);
    }
    .description {
        font-size: 16px;
    }
    .description .think-def {
        font-size: 22px;
    }
    .rate-us {
        transform: scale(.8) translateX(-55px);
    } 
    .try-Zbot {
      right: 10px;
      transform: scale(.8);
      bottom: 120px;
    }
  }
  @media(max-width : 710px) {
    .landing {
        padding-left: 20px;
    }
  }
  @media(max-width : 710px) {
    .landing {
        padding-left: 0;
        margin-left: -20px;
        transform: scale(.8);
    }
    .confirm-form {
      width: 410px;
      height: 276px;
      position: relative;
      border-radius: 16px;
      background-color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .confirm-form::before{
      width: 416px;
      height: 282px;
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      background: linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%);
      border-radius: 20px;
      z-index: -1;
    }
    .confirm-form p {
      margin-top: 0;
    }
    .confirm-form p , .confirm-form h3 , .confirm-form .btns-form {
      transform: scale(.8);
    }
    .confirm-form .btns-form {
      bottom: 30px;
    }
    .thx-form {
      width: 410px;
      height: 324px;
    }
    .thx-form::before {
      width: 416px;
      height: 330px;
    }
    .thx-form .true-sign {
      transform: scale(.8);
      margin-top: 10px;
    }
    .thx-form h3 {
      transform: scale(.8);
    }
    .thx-form p {
      transform: scale(.8);
    }
    .thx-form .have-say {
      transform: scale(.8);
    }
    .have-say-2 {
      scale: 0.9;
    }
    .thx-form .have-say-2 {
      transform: scale(.7);
    }
    .thx-form .have-say:hover {
      transform: scale(.9) !important;
    }
  }
  @keyframes bot-top {
    0% {
      bottom: 20px;
    }
    100% {
      bottom: 80vh;
    }
  }
  @keyframes bot-bottom {
    0% {
      bottom: 80vh;
    }
    100% {
      bottom: 20px;
    }
  }
  @media(max-width : 500px) {
    .canvas-bot {
      width: 90% !important;
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      width: 90%;
    }
    .chat-text {
      font-size: 11px !important;
      max-width: 80% !important;
    }
    .chat-text-user {
      font-size: 11px !important;
      max-width: 130px;
    }
    .messageing-area {
      width: 280px;
      right: 10px;
    }
    .messageing-area input {
      width: 60%;
      font-size: 14px;
    }
    .confirm-form {
      width: 280px;
      height: 256px;
      position: relative;
      border-radius: 16px;
      background-color: #000;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .confirm-form::before{
      width: 286px;
      height: 262px;
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      background: linear-gradient(103deg, #00FEFC -41.67%, #0002FE 141.58%);
      border-radius: 20px;
      z-index: -1;
    }
    .confirm-form p {
      margin-top: -20px;
      width: 300px;
    }
    .confirm-form h3 {
      width: 150% !important;
    }
    .confirm-form p , .confirm-form h3 , .confirm-form .btns-form {
      transform: scale(.6);
    }
    .confirm-form .btns-form {
      bottom: 30px;
    }
    .thx-form {
      width: 310px;
      height: 244px;
    }
    .thx-form::before {
      width: 316px;
      height: 250px;
    }
    .thx-form .true-sign {
      transform: scale(.5);
      margin-top: 5px;
    }
    .thx-form h3 {
      margin-top: 0;
      transform: scale(.6);
    }
    .thx-form p {
      margin-top: -20px;
      transform: scale(.6);
    }
    .thx-form .have-say {
      bottom: 10px;
      transform: scale(.6);
    }
    .thx-form .have-say-2 {
      transform: scale(.6);
    }
    .thx-form .have-say:hover {
      transform: scale(.6) !important;
    }
  }
  .active circle{
    /* transition-delay: .5s; */
    /* transition-duration: 1.2s; */
    fill: #01B9FD;
    fill-opacity: 100;
  }
  .dots {
  }
  .dots-fill {
    /* z-index: -1; */
    position: absolute;
    transform: translateX(2px) translateY(-3px);
  }
  .img-fill-rate {
    transition-duration: .2s;
    cursor: pointer;
    opacity: 0%;
    position: absolute;
  }
  #fill-rate-1 {
    top: 1px;
    left: -4px;
    transform: rotate(0.6deg);
  }
  #fill-rate-2 {
    top: 1px;
    left: 54px;
    transform: rotate(0deg);
  }
  #fill-rate-3 {
    top: 0px;
    left: 112px;
    transform: rotate(0.5deg);
  }
  #fill-rate-4 {
    top: 0px;
    left: 169px;
    transform: rotate(0.5deg);
  }
  #fill-rate-5 {
    top: 0px;
    left: 226px;
    transform: rotate(0.5deg);
  }
  #fill-rate-6 {
    /* z-index: -1; */
    top: 0px;
    left: 278px;
    transform: rotate(0.5deg);
    width: 50px;
    height: 50px;
  }
  .visible {
    transition-duration: .2s;
    width: 94px;
    opacity: 100 !important;
    z-index: 2 !important;
  }
  .bottom-sec {
    position: relative;
  }
  .bottom-sec::before {
    opacity: 0;
    position: absolute;
    content: "Memories";
    background: linear-gradient(103deg, #00cffe -41.67%, #3636ff 141.58%);
    font-family: "Hess Gothic Round NF W01 Bold";
    color: white;
    left: -82px;
    padding: 5px 10px;
    border-radius: 10px  0 10px 10px ;
    bottom: -32px;
    transition-duration: .4s;
  }
  .bottom-sec:hover::before {
    opacity: 100;
    transition-duration: .4s;
  }
  .bottom-sec-memo {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: #000;
    width: 50px;
    height: 50px;
    z-index: 4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom-sec-memo::before {
    opacity: 0;
    position: absolute;
    content: "Memories";
    background: linear-gradient(103deg, #00cffe -41.67%, #3636ff 141.58%);
    font-family: "Hess Gothic Round NF W01 Bold";
    color: white;
    left: 35px;
    padding: 5px 10px;
    font-size: 10px;
    border-radius: 10px 10px 10px 10px ;
    bottom: 5px;
    transition-duration: .4s;
    letter-spacing: 2px;
    z-index: 4;
  }
  .bottom-sec-memo:hover::before {
    opacity: 100;
    transition-duration: .4s;
  }
  .ZBOT {
    font-size: 65px;
    transform: translateY(-13px);
    font-family: 'Gilroy-Medium', sans-serif;
  }